import React from "react"
import { shape, string } from "prop-types"
import { Link, injectIntl } from "gatsby-plugin-intl"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/atoms/page-title"

const propTypes = {
  intl: shape().isRequired,
  location: shape().isRequired,
  path: string.isRequired,
}

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.currentColours.fg};
`

const slugs = {
  en: "",
  fi: "",
}

const NotFoundPage = ({ intl, location, path }) => (
  <Layout slugs={slugs} location={location.pathname} currentPath={path}>
    <SEO
      title={intl.formatMessage({ id: "notfound.header" })}
      location={location.pathname}
    />
    <PageTitle>{intl.formatMessage({ id: "notfound.header" })}</PageTitle>
    <p>{intl.formatMessage({ id: "notfound.description" })}</p>
    <p>
      <StyledLink to="/">
        {intl.formatMessage({ id: "notfound.link_text" })}
      </StyledLink>
    </p>

    <div style={{ display: "none" }}>
      <form
        name="contact_form"
        method="post"
        action="fi/thanks"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="contact_form" />
        <input
          type="checkbox"
          id="remote_meeting"
          name="remote_meeting"
          aria-label="Varaa aika etäpalaveriin"
        />
        <input type="text" name="date" />
        <select name="time">
          <option value="">Valitse aikaikkuna</option>
          <option value="9-13">9-13</option>
          <option value="13-17">13-17</option>
        </select>
        <input type="text" placeholder="Nimi*:" name="name" aria-label="Nimi" />
        <input
          type="text"
          placeholder="Yritys:"
          name="company"
          aria-label="Yritys"
        />
        <input
          type="text"
          placeholder="Puhelinnumero:"
          name="phone"
          aria-label="Puhelinnumero"
        />
        <input
          type="email"
          placeholder="Sähköpostiosoite*:"
          name="email"
          aria-label="Sähköpostiosoite"
        />
        <textarea
          placeholder="Viestisi*:"
          name="message"
          aria-label="Viestisi"
        />
        <button type="submit">Lähetä</button>
      </form>
    </div>
  </Layout>
)

NotFoundPage.propTypes = propTypes

export default injectIntl(NotFoundPage)
