import styled from "@emotion/styled"

const PageTitle = styled.h1`
  font-family: ${props => props.theme.typography.standoutFamily};
  font-weight: ${props => props.theme.typography.standoutWeight};
  font-size: 370%;
  line-height: 1.2;
  margin-bottom: 0.5em;
  @media ${props => props.theme.breakpoint.mobile} {
    font-size: 370%;
  }
  @media ${props => props.theme.breakpoint.tablet} {
    font-size: 315%;
  }
  @media ${props => props.theme.breakpoint.desktop} {
    font-size: 395%;
  }
`

export default PageTitle
